@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css');
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
$container-padding-x: 2.2rem;
@import '~bootstrap/scss/bootstrap';

$blue: #2282ff;
$darkblue: #22273f;
$green: #8ce67c;
$pink: #e67c7c;
$white: #f8f8f8;
$font-text: 'Manrope', sans-serif;
$font-title: 'Oswald', sans-serif;

.container-fluid {
   max-width: 1635px;
}

html {
   font-size: 62.5%;
   -ms-text-size-adjust: 100%;
}

.news-div-img {
   position: relative;
   object-fit: contain;
   padding-bottom: 52.63%;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
}

.news-text {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
   overflow: hidden;
}

h1 {
   font-size: 9rem;
}

h2 {
   font-size: 5rem;
}

h3 {
   font-size: 3.2rem;
}

h4 {
   font-size: 2.1rem;
}

h5 {
   font-size: clamp(3.2rem, 4vw, 5rem);
   font-family: $font-text;
}

a {
   font-size: 1.4rem;
   text-decoration: none;
}

p {
   font-family: $font-text;
   font-size: 1.6rem;
}

li {
   list-style: none;
}

h1,
h2,
h3,
h4,
a {
   font-family: $font-title;
}

h1,
h2,
h3,
h4 {
   text-transform: uppercase;
   font-weight: bold;
}

// ----- HOME -----
.homepage {
   h2 {
      text-transform: none;
      font-family: 'Manrope', sans-serif;
      bottom: 0;
      margin: 0;
      width: 51%;
      color: $white;
      padding: 100px 0 100px 0;
      position: relative;
      top: 100%;
      transform: translateY(-100%);
      font-size: 3.4vw;
      z-index: 111;
   }

   .banner-div {
      background-size: cover;
      position: relative;

      .container-fluid {
         height: 94vh;
      }
   }

   .banner-div::after {
      content: ''; // ::before and ::after both require content
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(16, 153, 255, 0) 60%, rgb(34 130 255 / 66%));
      opacity: 0.7;
   }

   .img-fluid {
      width: 100%;
      height: auto;
      max-height: 84.4rem;
      object-fit: cover;
   }

   .marquee {
      position: relative;
      overflow: hidden;
      --offset: 20vw;
      --move-initial: calc(-25% + var(--offset));
      --move-final: calc(-50% + var(--offset));
   }

   .marquee__inner {
      width: fit-content;
      display: flex;
      position: relative;
      transform: translate3d(var(--move-initial), 0, 0);
      animation: marquee 9s linear infinite;
      animation-play-state: running;
   }

   .marquee span {
      font-size: 18rem;
      text-transform: uppercase;
      width: 100rem;
      text-align: center;
      -webkit-text-stroke: 2px $blue; /* width and color */
      font-family: 'Oswald', sans-serif;
      color: rgb(255, 255, 255);
   }

   @keyframes marquee {
      0% {
         transform: translate3d(var(--move-initial), 0, 0);
      }

      100% {
         transform: translate3d(var(--move-final), 0, 0);
      }
   }

   @media only screen and(min-width: 1560px) {
      h2 {
         font-size: 5rem;
         width: 46%;
      }
   }

   @media only screen and (max-width: 992px) {
      h2 {
         font-size: 7.2vw;
         width: 51vw;
      }
   }

   @media only screen and (max-width: 375px) {
      h2 {
         padding: 100px 0 50px 0;
         font-size: 3.2rem;
         width: 69%;
      }
   }
}

.data-day {
   // .row > * {
   //    padding-right: 0 !important;
   //    padding-left: 0 !important;
   // }

   p {
      margin: 0;
   }

   .info_title {
      text-transform: uppercase;
      text-align: center;

      p {
         font-weight: bold;
      }
   }

   h3 {
      margin-bottom: 0;
   }

   .status {
      background-color: $green;
      text-align: center;
   }

   .closed {
      background-color: #e67c7c;
   }

   .time {
      margin: 0 auto;
      text-align: center;

      div {
         border: 0.9px solid black;
      }
   }
}

.data-aggr {
   .banner {
      background-color: $darkblue;
      align-items: center;
   }

   .image-right,
   .image-left {
      width: 10%;
      height: 80%;
   }

   .image-left {
      transform: rotate(180deg);
   }

   .stats {
      color: white;
      text-align: center;
      text-align: center;

      h3 {
         -webkit-text-stroke: 1px rgb(177, 177, 177);
         font-family: Arial, Helvetica, sans-serif;
         color: transparent;
         // font-size: 18rem;
         font-size: clamp(12rem, 12.5vw, 18rem);
      }

      h4 {
         color: $blue;
         margin-top: -34px;
         margin-bottom: 34px;
      }
   }

   @media only screen and (max-width: 576px) {
      .stats {
         h4 {
            color: $blue;
            margin-bottom: 0px;
            margin-top: -10px;
            font-size: 2.1rem;
         }
      }
   }
}

.news-cards-home {
   h4 {
      color: #2282ff;
   }

   .content {
      img {
         width: 100%;
      }
   }

   a {
      color: #2282ff;
      text-transform: uppercase;
      font-weight: bold;
   }

   .news-text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
   }

   a:hover {
      text-decoration: none;
   }
}

.carousel-comp {
   .arrow-prev,
   .arrow-next {
      svg {
         transform: translateY(-353%);
      }
   }

   .pagination {
      width: auto;
   }

   img {
      width: 100%;
   }

   a {
      color: $blue;
      text-transform: uppercase;
      font-weight: bold;
   }
}

// ----- SHIP CARDS -----
.ship-card {
   text-align: center;

   section {
      object-fit: contain;
      padding-bottom: 65%;
      width: 100%;
      background-position: center;
   }

   img {
      width: 100%;
   }

   .card {
      margin-bottom: 10%;
      // width: 10vw;
   }

   .card-body {
      padding: 0;
      min-height: 543px;

      div {
         padding: 20px;

         a {
            text-decoration: none;
            text-transform: uppercase;
            // font-size: 1.4rem;
            font-weight: bold;
            color: $blue;
         }
      }

      img {
         margin-bottom: 5%;
      }

      p {
         margin: 0;
         padding: 0 2vw 0 2vw;
         // font-size: 1.4rem;
      }
   }

   .card-title {
      // font-size: 1.3vw;
      padding: 0 1vw 0 0;
   }

   @media only screen and (max-width: 992px) {
      img {
         width: 100%;
      }
   }

   @media only screen and (max-width: 768px) {
   }

   @media only screen and (max-width: 576px) {
   }
}

// ----- AUCTION -----
.auction {
   section {
      background-color: $darkblue;
   }

   .arrow-div {
      z-index: 1111;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -550%);
   }

   .banner-div {
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      max-height: 84.4rem;
   }

   .banner {
      width: 100%;
      position: relative;
      padding: 13px;

      div {
         align-items: center;
         display: flex;

         .row {
            margin-right: 0 !important;
            margin-left: 0 !important;
         }
      }

      img {
         position: relative;
         z-index: 1;
      }
   }
   .carousel_img {
      width: 100%;
      height: 93.9rem;
      object-fit: fill;
   }

   .banner_title {
      color: transparent;
      text-transform: uppercase;
      text-align: left;
      font-weight: bold;
      font-size: 5.5vw;
      margin-left: -17rem;
      position: relative;
      z-index: 2;
      line-height: 1;
      -webkit-text-stroke: 2px #2282ff;
      padding: 4.5rem 7rem 4.5rem 7rem;
   }

   h3 {
      color: $blue;
   }

   .bottom-section {
      background-color: $white;
   }

   @media only screen and (min-width: 1635px) {
      .banner_title {
         font-size: 9rem;
      }
   }

   @media only screen and (max-width: 576px) {
      .banner_title {
         font-size: 3.2rem;
         margin-left: 2rem;
         padding: 0;
         -webkit-text-stroke: 2px $blue;
      }

      .banner-div {
         max-height: 31rem;
      }

      .banner {
         padding: 63px 0px 140px 0px;
         img {
            width: 26rem;
            height: 17.4rem;
            position: absolute;
            left: -0.9rem;
            top: 15rem;
         }
      }

      .carousel_img {
         height: 31.2rem;
         width: 100%;
      }
   }
}

.participate {
   img {
      width: 110.28px;
      height: 169.5px;
   }

   h3 {
      color: $blue;
   }

   .bottom-section {
      background-color: $white;
   }

   ul {
      margin: 0;
      padding: 0;

      li {
         font-size: 1.6rem;
         margin: 0 auto;
      }
   }

   .logo-div-bottom {
      height: auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      div:nth-child(2) {
         text-align: center;
      }
   }

   .bottom-content {
      background-color: $white;

      .text-div {
         h3 {
            color: $darkblue;
         }
      }
   }
}

// ----- FOOTER -----
.footer {
   .footer-container {
      color: $white;
      background-color: $darkblue;
   }

   // img {
   //    width: 49.8rem;
   //    height: 39.6rem;
   // }

   .footer-div {
      a {
         font-size: 2.1rem;
      }
   }

   .footer-links {
      li {
         text-transform: uppercase;
      }

      a {
         color: $white;
      }

      a:hover {
         text-decoration: none;
      }
   }

   hr {
      background-color: $blue;
      width: 100%;
   }

   h4 {
      color: $blue;
   }

   .footer-links-bottom {
      background-color: $blue;

      p {
         margin: 0;
         color: black;
      }

      a {
         margin: 10px;
         color: black;
         font-family: $font-text;
      }
   }

   @media only screen and (max-width: 992px) {
      .footer-links-mid {
         div {
            text-align: center;
         }
      }

      .footer-div {
         a {
            font-size: 1.4rem;
         }
      }
   }

   @media only screen and (max-width: 576px) {
      img {
         width: 100%;
         height: 20.9rem;
      }
   }
}

// ----- PAGE VIEW -----
.page-view {
   .banner {
      background-size: cover;
      position: relative;
      max-height: 30vh;
      z-index: -1;

      .cont {
         height: auto;
         padding-bottom: 32%;
      }
   }

   .inner-div {
      margin-left: 50%;
      transform: translate(-50%);

      h2 {
         text-transform: uppercase;
         text-align: center;
      }
   }
}

// ----- NAV -----
.navbar-component {
   background-color: white !important;

   .navbar-collapse {
      flex-grow: 0 !important;
   }

   .navbar-brand,
   img {
      z-index: 999;
   }

   .navbar-toggler-icon {
      width: 3.5em;
      height: 3.5em;
   }

   .navbar-toggler {
      z-index: 999;
   }

   ul {
      margin-top: 0;
      margin-bottom: 0;
   }

   li {
      list-style: none;
   }

   a {
      color: black;
      font-size: 1.3rem;
      text-decoration: none;
      font-family: $font-text;
   }

   a:hover {
      text-decoration: none;
   }

   .overlay {
      width: 100%;
      position: fixed;
      z-index: 5;
      left: 0;
      top: 0;
      background-color: white;
      overflow-x: hidden;
      transition: 0.5s;
      overflow-y: hidden;
      background: linear-gradient(#22273f, #2282ff);
      background: linear-gradient(#22273f, #2282ff);
   }

   .overlay-content {
      position: relative;

      width: 100%;
      text-align: center;
      margin-top: 13rem;
   }

   .overlay a {
      text-decoration: none;
      font-size: 36px;
      color: white !important;
      display: block;
      transition: 0.5s;
      text-transform: uppercase;
   }

   ul {
      padding-left: 15px;
      padding-right: 15px;

      p {
         bottom: 0;
         width: 60%;
         margin-top: 80%;
         color: white;
         padding-left: 15px;
         padding-right: 15px;
      }
   }

   hr {
      width: 1.3rem;
   }

   .overlay .closebtn {
      position: absolute;
      top: 0px;
      right: 25px;
      font-size: 60px;
   }

   .visible {
      height: 100%;
   }

   .hidden {
      height: 0%;
   }

   .collapsed {
      width: 45px;
      height: 20px;
      display: block;
      position: relative;
      z-index: 999;

      &:before,
      &:after {
         content: '';
         display: block;
         width: 100%;
         height: 4px;
         border-radius: 0;
         background: rgb(255, 255, 255);
         position: absolute;
         transition: all 0.2s ease-in;
         transform: scaleX(-1);

         transform: rotate(45deg);
         top: 8px;
      }
      &:after {
         top: auto;
         bottom: 8px;
         width: 100%;
         transform: rotate(-45deg);
      }
      &:before {
         transform: scaleX(-1) rotate(-45deg);
      }

      .icon-bar {
         position: absolute;
         height: 0;
         width: 100%;
         top: 17px;
         display: block;
         transition: all 0.2s ease-in;
      }
   }

   .not-collapsed {
      width: 45px;
      height: 20px;
      display: block;
      position: relative;
      z-index: 999;

      &:before,
      &:after {
         content: '';
         display: block;
         width: 100%;
         height: 4px;
         border-radius: 0;
         background: #22273f;
         position: absolute;
         top: 0;
         transition: all 0.2s ease-in;
         transform: scaleX(-1);
      }
      &:after {
         top: auto;
         bottom: 0;
         right: 0;
      }

      .icon-bar {
         position: absolute;
         height: 0;
         width: 100%;
         top: 17px;
         display: block;
         transition: all 0.2s ease-in;
      }
   }

   @media (max-width: 991.98px) {
      .navbar-nav {
         margin-left: initial !important;
      }

      .overlay {
         ul {
            align-items: left;
            text-align: left;
         }
      }
   }

   @media only screen and (max-width: 576px) {
      .navbar-toggler-icon {
         width: 2.5em;
         height: 2.5em;
      }

      .logo-mobile {
         height: 5.5rem;
         width: 20.2rem;
      }
      .overlay {
         ul {
            align-items: left;
            text-align: left;
         }
      }

      .overlay a {
         font-size: 3.2rem;
      }
      .overlay .closebtn {
         font-size: 40px;
      }
   }
}

// ----- NEWS LIST -----

img {
   width: 100%;
   height: 40%;
}

.news-div {
   margin-bottom: 60px;
   width: 100%;

   h5 {
      font-size: 5rem;
      font-family: $font-text;
   }

   .date {
      color: $blue;
   }

   a {
      text-transform: uppercase;
      font-weight: bold;
   }
}

.custom-select {
   text-transform: lowercase;
}

@media only screen and (max-width: 576px) {
   .news-div {
      h4 {
         font-size: 2.1rem;
      }

      h5 {
         font-size: 3.2rem;
      }
   }
}

// ----- CONTACTS -----
.contacts {
   #map,
   .loading {
      height: 40rem;
      z-index: 1;
   }

   h1 {
      color: $darkblue;
   }
}

// ----- ABOUT -----
.about {
   .fleet {
      background-color: $darkblue;
   }

   h2 {
      color: $blue;
   }

   .banner {
      padding-bottom: 30%;
      width: 50%;
      object-fit: contain;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
   }

   .card-1 {
      margin: 0 auto;
      align-items: center;
   }

   .card-1:nth-of-type(even) {
      flex-direction: row-reverse;
   }

   .text {
      width: 40%;
   }

   img {
      width: 50%;
   }

   @media only screen and (max-width: 992px) {
      .banner {
         padding-bottom: 50%;
         width: 100%;
      }
   }
}

.content {
   div {
      a {
         text-transform: uppercase;
         font-weight: bold;
      }
   }

   .date {
      color: $blue;
      margin: 0;
   }
}

.link-div {
   text-align: right;
   margin: 0 auto;
   text-transform: uppercase;
}

// ----- NEWS VIEW -----
.news-view {
   h1 {
      color: $darkblue;
      font-size: 5rem !important;
   }

   .news-view-banner {
      position: relative;
      object-fit: contain;
      padding-bottom: 52.63%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
   }

   .news_card {
      padding-right: 10px;

      div {
         text-align: right;

         a {
            text-align: right;
            color: $blue;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 2.1rem;
         }
      }
   }

   h5 {
      font-size: 5rem;
      font-family: $font-text;
   }

   h4 {
      font-size: 2.1rem;
   }

   .outer_content {
      div {
         width: 100%;
         margin: 0 auto;
      }

      h2 {
         text-transform: uppercase;
         color: $blue;
      }
   }

   @media only screen and (max-width: 768px) {
      .news_card {
         padding-right: 0;
      }
   }

   @media only screen and (max-width: 576px) {
      .news_card {
         padding-right: 0;

         div {
            a {
               font-size: 1.4rem;
            }
         }
      }

      h5 {
         font-size: 3.2rem;
      }
   }
}

// ----- SHIP VIEW -----
.ship-view {
   hr {
      background-color: $blue;
      height: 0.5rem;
   }

   section {
      background-color: $darkblue;
   }

   h1 {
      color: $blue;
      color: transparent;
      -webkit-text-stroke: 2px #2282ff;
      font-family: Arial, Helvetica, sans-serif;
   }

   h2 {
      color: $blue;
   }

   p,
   .desc {
      color: $white;
   }

   .specs {
      .value {
         color: $blue;
      }
   }

   a {
      color: $white;
      text-transform: uppercase;
      text-decoration: none;
   }

   h3 {
      color: $blue;
   }

   .captain_image {
      max-width: 300px;
   }

   .captain_desc {
      width: 50%;
   }

   .card-body {
      p {
         color: black;
      }

      .link {
         color: $blue;
         text-transform: uppercase;
         font-weight: bold;
      }
   }

   @media only screen and (max-width: 992px) {
      .captain_image {
         width: 100%;
         max-width: 100%;
      }

      .captain_desc {
         width: 100%;
      }
   }

   @media only screen and (max-width: 576px) {
      h1 {
         font-size: 3.2rem;
      }
   }
}

// ----- GLOBAL MEDIA QUERY -----
@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 576px) {
   h2 {
      font-size: 3.2rem;
   }

   h3 {
      font-size: 2.1rem;
   }

   h4,
   p {
      font-size: 1.4rem;
   }
}
